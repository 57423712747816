import type { Environment } from './types';
import { sharedDevAuEnv, sharedDevEnv, sharedPilotEnv } from './shared';

export const env: Environment = {
  ...sharedDevEnv,
  ...sharedPilotEnv,
  ...sharedDevAuEnv,
  adminAppUrl: 'https://dev.admin.pilot.com.au',
  logoUrl: 'https://assets.pilot.com.au/pilot-logo.svg',
};
